import { BehaviorPreset } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/automation_pb';
import { DialogIdentity } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/identities_pb';
import { NotificationDialogUnreadUpdated } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/notifications_pb';
import {
  Session,
  Status,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';
import { Gift } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/gift_pb';

import {
  ChatState,
  ChatStateDialog,
  ChatStateDialogMessage,
  ChatStateManagementSession,
  ChatStateModal,
} from '@/pages/chat/store/chat-state';

type MutationChatAddMessagesPayload = {
  messages: ChatStateDialogMessage[];
  dialogId: string;
};

type MutationChatAddMessagePayload = {
  message: ChatStateDialogMessage;
  dialogId: string;
};

type MutationChatDeleteMessagePayload = {
  dialogId: string;
  messageId: string;
};

type MutationChatUpdateUserSubscriptionPayload = {
  userId: string;
  premiumActive: boolean;
  isTrial: boolean;
};

type MutationChatSuspiciousContentReportedPayload = {
  dialogId: string;
  messageId: string;
};

type MutationChatSetDialogBlockedPayload = {
  userId: string;
  chatterId: string;
};

type MutationChatDeleteMessagesPayload = {
  chatId: string;
  messageIds: string[];
};

declare module '@/store/store-helper' {
  interface Mutation {
    mutationChatSetDialogCounters: NotificationDialogUnreadUpdated.AsObject;
    mutationChatSetDialogBlocked: MutationChatSetDialogBlockedPayload;
    mutationChatSetDialogDeleted: string;
    mutationChatAddDialogs: ChatStateDialog[];
    mutationChatUpdateUserSubscription: MutationChatUpdateUserSubscriptionPayload;
    mutationChatClearDialogs: never;
    mutationChatSetDialogsAfter: string;
    mutationChatAddMessages: MutationChatAddMessagesPayload;
    mutationChatClearMessages: string;
    mutationChatSetMessagesAfter: string;
    mutationChatSetMessagesHeaderDialog: ChatStateDialog;
    mutationChatSetDialog: ChatStateDialog;
    mutationChatDeleteDialog: string;
    mutationChatAddMessage: MutationChatAddMessagePayload;
    mutationChatDeleteMessage: MutationChatDeleteMessagePayload;
    mutationChatSetGifts: Gift.AsObject[];
    mutationChatMakeAsRead: DialogIdentity.AsObject;
    mutationChatIncreaseDialogUnreadCounter: string;
    mutationChatAddDialogToTop: ChatStateDialog;
    mutationChatDeleteMessageModalShown: ChatStateModal;
    mutationChatReportSuspiciousContentModalShown: ChatStateModal;
    mutationChatDeleteMessages: MutationChatDeleteMessagesPayload;
    mutationChatSuspiciousContentReported: MutationChatSuspiciousContentReportedPayload;
    mutationChatSetSession: Session.AsObject | undefined;
    mutationChatSetSessionStatus: Status;
    mutationChatSessionStarted: never;
    mutationChatSessionStopped: never;
    mutationChatClearDialogsByIds: string[];
    mutationChatManagementAddSessions: ChatStateManagementSession[];
    mutationChatManagementClearSessions: never;
    mutationChatManagementDeleteSession: string;
    mutationChatManagementSetSessionsAfter: string;
    mutationChatAddPresetList: BehaviorPreset.AsObject[];
    mutationChatClearPresetAll: never;
    mutationChatSetPresetNextId: string;
    mutationChatSavePreset: BehaviorPreset.AsObject;
    mutationChatDeletePreset: string;
  }
}

export function mutationChatSetDialogCounters(
  state: ChatState,
  { dialogId, unreadCount, isNew }: NotificationDialogUnreadUpdated.AsObject,
): void {
  const dialog = state.dialogs.find((dialog) => dialog.id === dialogId);

  if (dialog) {
    dialog.unreadCount = unreadCount;
    dialog.isNew = isNew;
  }
}

export function mutationChatSetDialogBlocked(
  state: ChatState,
  { chatterId, userId }: MutationChatSetDialogBlockedPayload,
): void {
  const dialog = state.dialogs.find(
    (dialog) =>
      dialog.userProfile.profileInfo.id === userId &&
      dialog.chatterProfile.profileInfo.id === chatterId,
  );
  if (dialog) {
    dialog.isSubjectBlocked = true;
  }
}

export function mutationChatSetDialogDeleted(state: ChatState, dialogId: string): void {
  const dialog = state.dialogs.find((dialog) => dialog.id === dialogId);
  if (dialog) {
    dialog.isDialogDeleted = true;
  }
}

export function mutationChatAddDialogs(state: ChatState, dialogs: ChatStateDialog[]): void {
  state.dialogs.push(...dialogs);
}

export function mutationChatUpdateUserSubscription(
  state: ChatState,
  { userId, premiumActive, isTrial }: MutationChatUpdateUserSubscriptionPayload,
): void {
  state.dialogs
    .filter(({ userProfile }) => userProfile.profileInfo.id === userId)
    .forEach((dialog) => {
      const user = dialog.userProfile;
      user.profileState = {
        ...user.profileState,
        isTrial,
        premiumActive,
      };
    });
}

export function mutationChatClearDialogs(state: ChatState): void {
  state.dialogs = [];
}

export function mutationChatClearDialogsByIds(state: ChatState, dialogIds: string[]): void {
  // Remove dialogs which ids in dialogIds.
  state.dialogs = state.dialogs.filter((dialog) => !dialogIds.includes(dialog.id));
}

export function mutationChatSetDialogsAfter(state: ChatState, dialogsAfter: string): void {
  state.dialogsAfter = dialogsAfter;
}

export function mutationChatAddMessages(
  state: ChatState,
  { messages, dialogId }: MutationChatAddMessagesPayload,
): void {
  if (!state.messages[dialogId]) {
    state.messages[dialogId] = [];
  }

  const stateMessages = state.messages[dialogId];
  messages.forEach((message) => {
    const index = stateMessages.findIndex((stateMessage) => stateMessage.id === message.id);
    if (index !== -1) {
      stateMessages[index] = message;
    } else {
      stateMessages.unshift(message);
    }
  });
}

export function mutationChatAddMessage(
  state: ChatState,
  { message, dialogId }: MutationChatAddMessagePayload,
): void {
  if (!state.messages[dialogId]) {
    state.messages[dialogId] = [];
  }
  const messages = state.messages[dialogId];
  const index = messages.findIndex((m) => m.id === message.id);

  // if we already have message with provided id
  if (index !== -1) {
    messages[index] = message;
  } else {
    messages.push(message);
  }
}

export function mutationChatDeleteMessage(
  state: ChatState,
  { dialogId, messageId }: MutationChatDeleteMessagePayload,
): void {
  const messages = state.messages[dialogId];

  if (messages) {
    const index = messages.findIndex((message) => message.id === messageId);

    if (index !== -1) {
      messages.splice(index, 1);
    }
  }
}

export function mutationChatSuspiciousContentReported(
  state: ChatState,
  { dialogId, messageId }: MutationChatDeleteMessagePayload,
): void {
  const dialog = state.messages[dialogId];

  if (dialog) {
    const message = dialog.find((msg) => msg.id === messageId);

    if (message) {
      message.isSuspiciousContent = true;
    }
  }
}

export function mutationChatClearMessages(state: ChatState, dialogId: string): void {
  state.messages[dialogId] = [];
}

export function mutationChatSetMessagesAfter(state: ChatState, messagesAfter: string): void {
  state.messagesAfter = messagesAfter;
}

export function mutationChatSetDialog(state: ChatState, dialog: ChatStateDialog): void {
  const dialogIndex = state.dialogs.findIndex((item) => item.id === dialog.id);

  if (dialogIndex !== -1) {
    state.dialogs[dialogIndex] = dialog;
  }
}

export function mutationChatDeleteDialog(state: ChatState, dialogId: string): void {
  const dialogIndex = state.dialogs.findIndex((item) => item.id === dialogId);

  if (dialogIndex !== -1) {
    state.dialogs.splice(dialogIndex, 1);
  }
}

export function mutationChatSetGifts(state: ChatState, gifts: Gift.AsObject[]): void {
  state.gifts = gifts;
}

export function mutationChatMakeAsRead(state: ChatState, identity: DialogIdentity.AsObject): void {
  const dialog = state.dialogs.find((dialog) => dialog.id === identity.dialogId);

  if (dialog) {
    dialog.unreadCount = 0;
  }
}

export function mutationChatIncreaseDialogUnreadCounter(state: ChatState, dialogId: string): void {
  const index = state.dialogs.findIndex((dialog) => dialog.id === dialogId);

  if (index !== -1) {
    const dialog = state.dialogs[index];
    dialog.unreadCount += 1;

    // If index is not on the top
    if (index !== 0) {
      state.dialogs.splice(index, 1);
      state.dialogs.unshift(dialog);
    }
  }
}

export function mutationChatAddDialogToTop(state: ChatState, dialog: ChatStateDialog): void {
  state.dialogs.unshift(dialog);
}

export function mutationChatDeleteMessageModalShown(
  state: ChatState,
  { isVisible, selectedMessageId }: ChatStateModal,
): void {
  state.deleteMessageModal.isVisible = isVisible;
  state.deleteMessageModal.selectedMessageId = selectedMessageId;
}

export function mutationChatReportSuspiciousContentModalShown(
  state: ChatState,
  { isVisible, selectedMessageId }: ChatStateModal,
): void {
  state.reportSuspiciousContentModal.isVisible = isVisible;
  state.reportSuspiciousContentModal.selectedMessageId = selectedMessageId;
}

export function mutationChatDeleteMessages(
  state: ChatState,
  { chatId, messageIds }: MutationChatDeleteMessagesPayload,
): void {
  if (state.messages[chatId]) {
    state.messages[chatId] = state.messages[chatId].filter(
      (message) => messageIds.findIndex((id) => id === message.id) === -1,
    );
  }
}

export function mutationChatSetSession(state: ChatState, session?: Session.AsObject): void {
  state.session = session;
}

export function mutationChatSetSessionStatus(state: ChatState, status: Status): void {
  if (state.session) {
    state.session.status = status;
  }
}

export function mutationChatSessionStarted(): void {
  // Event bus mutation.
}

export function mutationChatSessionStopped(): void {
  // Event bus mutation.
}

export function mutationChatManagementAddSessions(
  state: ChatState,
  sessions: ChatStateManagementSession[],
): void {
  state.managementSessions.push(...sessions);
}

export function mutationChatManagementDeleteSession(state: ChatState, teamMemberId: string): void {
  const index = state.managementSessions.findIndex(
    (session) => session.teamMemberId === teamMemberId,
  );

  if (index !== -1) {
    state.managementSessions.splice(index, 1);
  }
}

export function mutationChatManagementClearSessions(state: ChatState): void {
  state.managementSessions = [];
}

export function mutationChatManagementSetSessionsAfter(
  state: ChatState,
  sessionsAfter: string,
): void {
  state.managementSessionsAfter = sessionsAfter;
}

export function mutationChatAddPresetList(
  state: ChatState,
  presetList: BehaviorPreset.AsObject[],
): void {
  state.presetList.push(...presetList);
}

export function mutationChatClearPresetAll(state: ChatState): void {
  state.presetList = [];
  state.presetNextId = '';
}

export function mutationChatSetPresetNextId(state: ChatState, presetNextId: string): void {
  state.presetNextId = presetNextId;
}

export function mutationChatSavePreset(state: ChatState, preset: BehaviorPreset.AsObject): void {
  const presetIndex = state.presetList.findIndex((item) => item.id === preset.id);

  if (presetIndex !== -1) {
    state.presetList[presetIndex] = preset;
  } else {
    state.presetList.unshift(preset);
  }
}

export function mutationChatDeletePreset(state: ChatState, presetId: string): void {
  const presetIndex = state.presetList.findIndex((item) => item.id === presetId);

  if (presetIndex !== -1) {
    state.presetList.splice(presetIndex, 1);
  }
}
