import { GiftsFilter } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/gift_pb';
import {
  ListGiftsRequest,
  ListGiftsResponse,
  ListPackagesRequest,
  ListPackagesResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/market_api_pb';
import { MarketAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/Market_apiServiceClientPb';
import {
  PackagesFilter,
  PackageType,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/package_pb';
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const marketAPIClient = new MarketAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([marketAPIClient]);

export function listGifts(): Promise<ListGiftsResponse> {
  const active = new BoolValue();
  active.setValue(true);

  const filter = new GiftsFilter();
  filter.setActive(active);

  const request = new ListGiftsRequest();
  request.setFilter(filter);

  return makeRpcPromisable(marketAPIClient, marketAPIClient.listGifts, request);
}

export type ListPackagesTag = 'regular' | 'non-trial';

export function listPackages({ tags }: { tags: ListPackagesTag[] }): Promise<ListPackagesResponse> {
  const request = new ListPackagesRequest();
  const filter = new PackagesFilter();
  const bool = new BoolValue();

  filter.setType(PackageType.PACKAGE_TYPE_SUBSCRIPTION);
  filter.setActive(bool.setValue(true));
  filter.setIncludeTagsList(tags);
  request.setFilter(filter);

  return makeRpcPromisable(marketAPIClient, marketAPIClient.listPackages, request);
}
