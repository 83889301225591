import {
  ListDialogMessagesResponse,
  ListDialogsResponse,
  SendMessageResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/chatters_api_pb';
import { ListSessionsResponse } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/chatters_management_api_pb';
import {
  Dialog,
  DialogMessage,
  DialogMessageContent,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/dialog_pb';
import { DialogIdentity } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/identities_pb';
import { ProfileLocation } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/profile_pb';
import dayjs from 'dayjs';

import {
  ChatStateDialog,
  ChatStateDialogMessage,
  ChatStateDialogMessageContent,
  ChatStateManagementSession,
} from '@/pages/chat/store/chat-state';
import { defaultProfilePreview } from '@/store/modules/common/helpers/common-helper';

export const DIALOGS_LIMIT = 20;
export const MESSAGES_LIMIT = 20;
export const CHATTERS_MANAGEMENT_SESSIONS_LIMIT = 200;
export const DIALOGS_MACROS_LIMIT = 12;

export function getDialogFrom(
  dialog: Dialog,
  location?: ProfileLocation.AsObject,
  teamMemberEmail = '',
): ChatStateDialog {
  return {
    id: dialog.getId(),
    isDialogDeleted: dialog.getIsDialogDeleted(),
    isSubjectBlocked: dialog.getIsSubjectBlocked(),
    isUserDeleted: dialog.getIsUserDeleted(),
    unreadCount: dialog.getUnreadCount(),
    isNew: dialog.getIsNew(),
    isSubjectChatter: dialog.getIsSubjectChatter(),
    userProfile: {
      profileInfo: dialog.getUserProfile()?.getProfileInfo()?.toObject() ?? defaultProfilePreview(),
      profileState: dialog.getUserProfile()?.getProfileState()?.toObject() ?? {
        boosterActive: false,
        isTrial: false,
        packageId: '',
        premiumActive: false,
      },
    },
    chatterProfile: {
      profileInfo:
        dialog.getChatterProfile()?.getProfileInfo()?.toObject() ?? defaultProfilePreview(),
      profileState: dialog.getChatterProfile()?.getProfileState()?.toObject() ?? {
        boosterActive: false,
        isTrial: false,
        packageId: '',
        premiumActive: false,
      },
    },
    location: {
      city: location?.city ?? '',
      country: location?.country ?? '',
      state: location?.state ?? '',
    },
    isMarcoAvailable: dialog.getIsMarcoAvailable(),
    isPowerLike: dialog.getIsPowerLike(),
    teamMemberEmail,
    isSticky: dialog.getIsSticky(),
    priority: dialog.getPriority(),
    isAssistantAvailable: dialog.getIsAssistantAvailable(),
  };
}

export function getDialogsFrom(response: ListDialogsResponse): ChatStateDialog[] {
  const dialogs: ChatStateDialog[] = [];
  response.getDialogsList().forEach((dialog) => {
    dialogs.push(getDialogFrom(dialog));
  });

  return dialogs;
}

export function getDialogMessageContentFrom(
  messageContent: DialogMessageContent | undefined,
): ChatStateDialogMessageContent | undefined {
  let content: ChatStateDialogMessageContent | undefined;

  if (messageContent) {
    const contentCase = messageContent.getContentCase();

    switch (contentCase) {
      case DialogMessageContent.ContentCase.TEXT: {
        content = {
          type: 'text',
          text: messageContent.getText(),
        };

        break;
      }

      case DialogMessageContent.ContentCase.IMAGE: {
        content = {
          type: 'image',
          imageId: messageContent.getImage()?.getPhotoId() ?? '',
        };

        break;
      }

      case DialogMessageContent.ContentCase.GIFT: {
        const gift = messageContent.getGift();
        content = {
          type: 'gift',
          giftId: gift?.getGiftId() ?? '',
          giftPhotoId: gift?.getPhotoId() ?? '',
          giftText: gift?.getText() ?? '',
        };

        break;
      }

      default:
        break;
    }
  }

  return content;
}

export function getMessagesFrom(response: ListDialogMessagesResponse): ChatStateDialogMessage[] {
  const messages: ChatStateDialogMessage[] = [];
  response.getMessagesList().forEach((message) => {
    messages.push(getMessageFrom(message));
  });

  return messages;
}

export function getMyMessageFrom(
  identity: DialogIdentity.AsObject,
  content: ChatStateDialogMessageContent,
  result: SendMessageResponse,
  toUserId: string,
): ChatStateDialogMessage {
  const createdDurationMs = (result.getCreatedDuration()?.getSeconds() ?? 0) * 1000;
  const createdTimeMs = dayjs().subtract(createdDurationMs, 'millisecond').valueOf();

  return {
    id: result.getMessageId(),
    fromUserId: identity.chatterId,
    toUserId,
    fromIsChatter: true,
    toIsChatter: false,
    createdDurationMs,
    createdTimeMs,
    isNew: false,
    content,
    messagePosition: '',
    isSystem: false,
    isSuspiciousContent: false,
    isDeleted: false,
    operatorId: '',
  };
}

export function getMessageFrom(message: DialogMessage): ChatStateDialogMessage {
  return {
    id: message.getId(),
    content: getDialogMessageContentFrom(message.getContent()),
    createdDurationMs: (message.getCreatedDuration()?.getSeconds() ?? 0) * 1000,
    createdTimeMs: (message.getCreatedTime()?.getSeconds() ?? 0) * 1000,
    fromIsChatter: message.getFromIsChatter(),
    fromUserId: message.getFromUserId(),
    isNew: message.getIsNew(),
    isSystem: message.getIsSystem(),
    messagePosition: message.getMessagePosition(),
    toIsChatter: message.getToIsChatter(),
    toUserId: message.getToUserId(),
    isSuspiciousContent: message.getIsSuspiciousContent(),
    isDeleted: message.getIsDeleted(),
    operatorId: message.getOperatorId(),
  };
}

export function getChattersManagementSessionsFrom(
  response: ListSessionsResponse,
): ChatStateManagementSession[] {
  return response
    .getSessionsList()
    .map((session) => session.toObject() as ChatStateManagementSession);
}
