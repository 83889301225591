import { BehaviorPreset } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/automation_pb';
import {
  Dialog,
  DialogMessage,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/dialog_pb';
import { Profile } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/profile_pb';
import { Session } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';
import { ProfileLocation } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/profile_pb';
import { Gift } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/gift_pb';

import { Modify } from '@/helpers/type/type';

export type ChatStatePaneItem = {
  id: string;
  avatarId: string;
  name: string;
  age: number;
  isOnline: boolean;
  isTrial: boolean;
  isPremium: boolean;
  isBoosted: boolean;
  isBlocked: boolean;
  isDeleted: boolean;
  isPowerLike: boolean;
  isSticky: boolean;
  newCount: number;
  locationName: string;
  timezone: string;
  isExpired?: boolean;
};

export type ChatStateDialog = Modify<
  Dialog.AsObject,
  {
    userProfile: Required<Profile.AsObject>;
    chatterProfile: Required<Profile.AsObject>;
  }
> & { location: ProfileLocation.AsObject; teamMemberEmail: string };

export type ChatStateManagementSession = Required<Session.AsObject>;

export type ChatStateDialogMessageContentText = {
  type: 'text';
  text: string;
};

export type ChatStateDialogMessageContentImage = {
  type: 'image';
  imageId: string;
};

export type ChatStateModal = {
  isVisible: boolean;
  selectedMessageId: string;
};

export type ChatStateDialogMessageContentGift = {
  type: 'gift';
  giftId: string;
  giftPhotoId: string;
  giftText: string;
};

export type ChatStateDialogMessageContent =
  | ChatStateDialogMessageContentText
  | ChatStateDialogMessageContentImage
  | ChatStateDialogMessageContentGift;

export type ChatStateDialogMessage = Omit<
  DialogMessage.AsObject,
  'content' | 'createdDuration' | 'createdTime'
> & {
  content?: ChatStateDialogMessageContent;
  createdTimeMs: number;
  createdDurationMs: number;
};

export type ChatState = {
  dialogs: ChatStateDialog[];
  dialogsAfter: string;
  messages: { [key: string]: ChatStateDialogMessage[] };
  messagesAfter: string;
  deleteMessageModal: ChatStateModal;
  reportSuspiciousContentModal: ChatStateModal;
  gifts: Gift.AsObject[];
  session?: Session.AsObject;
  managementSessions: ChatStateManagementSession[];
  managementSessionsAfter: string;
  presetList: BehaviorPreset.AsObject[];
  presetNextId: string;
};

export const state: ChatState = {
  dialogs: [],
  dialogsAfter: '',
  messages: {},
  messagesAfter: '',
  deleteMessageModal: {
    isVisible: false,
    selectedMessageId: '',
  },
  reportSuspiciousContentModal: {
    isVisible: false,
    selectedMessageId: '',
  },
  gifts: [],
  session: undefined,
  managementSessions: [],
  managementSessionsAfter: '',
  presetList: [],
  presetNextId: '',
};
